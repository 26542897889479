import * as React from "react";
import { SEO } from "../components";

import { Layout, IntroSlide, AboutSlide, ExperienceSlide } from "../components";

const IndexPage = () => {
  return (
    <Layout>
      <IntroSlide />
      <AboutSlide />
      <ExperienceSlide />
    </Layout>
  );
};

export const Head = () => <SEO />;

export default IndexPage;
