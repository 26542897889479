import React from "react";
import TextSection from "./TextSection";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";

import SkillIcon from "./SkillIcon";

const SlideContainer = styled.section`
  margin-top: 40px;
  min-height: 70vh;
  gap: 2rem;

  @media screen and (max-width: 769px) {
    min-height: 0;
    margin-bottom: 40px;
  }
`;

const Columns = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
`;

const LeftContainer = styled.div`
  display: block;
  max-width: 50%;
  min-width: 200px;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const AboutSlide = () => {
  const { reactObj, tsObj, jsObj, htmlObj, cssObj, nextObj } =
    useStaticQuery(graphql`
      query AboutQuery {
        reactObj: file(name: { eq: "react_icon" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
        tsObj: file(name: { eq: "ts_icon" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
        jsObj: file(name: { eq: "js_icon" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
        htmlObj: file(name: { eq: "html_icon" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
        cssObj: file(name: { eq: "css_icon" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
        nextObj: file(name: { eq: "next_icon" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    `);

  return (
    <SlideContainer>
      <Columns>
        <LeftContainer>
          <h3>About me</h3>
          <p>
            Hi! I’m Sean and I’ve been working as a frontend developer for over
            5 years. I’m currently based in Dublin and actively looking for
            opportunities after having finished a Masters degree at University
            of Limerick. I have had the privilege to work with some incredibly
            talented developers at amazing companies such as Sky, Daft.ie and
            DoneDeal.
          </p>
        </LeftContainer>
        <RightContainer>
          <SkillIcon
            imageObj={reactObj}
            text="React"
            iconAlt="react icon"
          ></SkillIcon>
          <SkillIcon
            imageObj={tsObj}
            text="TypeScript"
            iconAlt="typescript icon"
          ></SkillIcon>
          <SkillIcon
            imageObj={jsObj}
            text="JavaScript (ES6+)"
            iconAlt="javascript icon"
          ></SkillIcon>
          <SkillIcon
            imageObj={htmlObj}
            text="HTML5"
            iconAlt="html 5 icon"
          ></SkillIcon>
          <SkillIcon
            imageObj={cssObj}
            text="CSS3"
            iconAlt="css 3 icon"
          ></SkillIcon>
          <SkillIcon
            imageObj={nextObj}
            text="Next.js"
            iconAlt="next JS icon"
          ></SkillIcon>
        </RightContainer>
      </Columns>
    </SlideContainer>
  );
};

export default AboutSlide;
