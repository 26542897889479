import React, { useState } from "react";
import styled from "styled-components";

import Skills from "./Skills";
import { usePicker } from "../hooks/usePicker";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10%;

  @media screen and (max-width: 769px) {
    flex-direction: column;
  }
`;

const LeftSide = styled.div`
  display: grid;
  grid-auto-flow: row;
  align-items: center;
  margin-right: 10px;
  height: 40vh;

  @media screen and (max-width: 769px) {
    margin-bottom: 10px;
    grid-auto-flow: column;
    height: 25px;
  }
`;

const RightSide = styled.div``;

const Title = styled.h2`
  color: #3bffe4;
  font-size: 25px; ;
`;

const SecondaryTitle = styled.span`
  color: #47b5ff;
  font-size: 20px;
`;

const ChooserItem = styled.div<{ isSelected: boolean }>`
  display: flex;
  cursor: pointer;
  color: ${(props) => (props.isSelected ? "#47B5FF" : "#256D85")};
  border-left: solid;
  height: 100%;
  align-items: center;
  padding-left: 5px;

  @media screen and (max-width: 769px) {
    border-left: 0;
    border-bottom: solid;
  }
`;

interface ChooserProps {
  items: {
    frontmatter: {
      title: string;
      primaryTitle: string;
      secondaryTitle?: string;
      skills?: string;
      dateFrom: string;
      dateTo: string;
      current?: string;
    };
    html: string;
  }[];
}

const dateString = (dateFrom: string, dateTo: string, current?: string) =>
  current ? `${dateFrom} - Current` : `${dateFrom} - ${dateTo}`;

const Chooser = ({ items }: ChooserProps) => {
  const { selectedIndex, getTabProps } = usePicker();

  const {
    frontmatter: {
      primaryTitle,
      secondaryTitle,
      skills,
      dateFrom,
      dateTo,
      current,
    },
    html,
  } = items[selectedIndex];

  return (
    <Container>
      <LeftSide>
        {items.map(({ frontmatter: { title } }, index) => (
          <ChooserItem key={title} {...getTabProps(index)}>
            <span>{title}</span>
          </ChooserItem>
        ))}
      </LeftSide>
      <RightSide>
        <Title>
          {primaryTitle}{" "}
          <SecondaryTitle>
            {secondaryTitle ? `- ${secondaryTitle} ` : ""} |{" "}
            {dateString(dateFrom, dateTo, current)}
          </SecondaryTitle>
        </Title>
        <p dangerouslySetInnerHTML={{ __html: html }} />
        <Skills skills={skills!} />
      </RightSide>
    </Container>
  );
};

export default Chooser;
