import React from "react";
import TextSection from "./TextSection";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import Chooser from "./Chooser";

const SlideContainer = styled.section`
  min-height: 100vh;
`;

const Title = styled.h3`
  margin-bottom: 40px;
  @media screen and (max-width: 769px) {
    min-height: 0;
    margin-bottom: 20px;
  }
`;

const ExperienceSlide = () => {
  const { experience } = useStaticQuery(graphql`
    query ExperienceQuery {
      experience: allMarkdownRemark(
        sort: { fields: frontmatter___dateFrom, order: DESC }
      ) {
        nodes {
          frontmatter {
            primaryTitle
            secondaryTitle
            title
            skills
            dateFrom(formatString: " MMMM YYYY")
            dateTo(formatString: " MMMM YYYY")
            current
          }
          html
        }
      }
    }
  `);

  return (
    <SlideContainer>
      <Title>What I've been up to</Title>
      <Chooser items={experience.nodes}></Chooser>
    </SlideContainer>
  );
};

export default ExperienceSlide;
