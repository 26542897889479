import { useState } from "react";

export const usePicker = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  return {
    selectedIndex,
    getTabProps: (index: number) => ({
      isSelected: index === selectedIndex,
      onClick: () => setSelectedIndex(index),
    }),
  };
};
