import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

import CTAIcon from "./CTAIcon";

const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 769px) {
    flex-direction: column;
  }
`;

const TitleContainer = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  max-width: 50%;

  @media screen and (max-width: 769px) {
    min-height: auto;
    margin-top: 70px;
    max-width: 100%;
  }
`;

const ProfileContainer = styled.div`
  position: relative;
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 5px solid rgba(71, 181, 255, 0.4);
    top: 10px;
    left: 10px;
    z-index: -1;
  }

  @media screen and (max-width: 769px) {
    margin-top: 40px;
  }
`;

const CTALinks = styled.div`
  display: flex;
  justify-content: space-around;
`;

const CTAContainer = styled.div`
  padding-top: 40px;
  width: 100%;
  margin-left: 20px;
`;

const RightSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const IntroSlide = () => {
  const { mail, cv, linkedin, github } = useStaticQuery(graphql`
    query IntroQuery {
      mail: file(name: { eq: "mail" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      cv: file(name: { eq: "file-text" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      linkedin: file(name: { eq: "linkedin" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      github: file(name: { eq: "github" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  return (
    <MainContainer>
      <TitleContainer>
        <h3>Hello, my name is</h3>
        <h1>Sean Dempsey.</h1>
        <h2>I develop frontend applications and websites.</h2>
      </TitleContainer>

      <RightSection>
        <ProfileContainer>
          <StaticImage
            src="../images/profile.png"
            alt="profile image"
            placeholder="tracedSVG"
            width={350}
          />
        </ProfileContainer>
        <CTAContainer>
          <CTALinks>
            <CTAIcon
              url="mailto:&#100;&#101;&#109;&#112;&#115;&#046;&#115;&#101;&#097;&#110;&#064;&#103;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;"
              imageObj={mail}
              iconAlt="email"
              underText="email"
            />
            <CTAIcon
              url="https://github.com/SeanDemps/"
              imageObj={github}
              iconAlt="github"
              underText="GitHub"
            />
            <CTAIcon
              url="../../CV.pdf"
              imageObj={cv}
              iconAlt="resume"
              underText="Resume"
              useHTMLlink
            />
            <CTAIcon
              url="https://www.linkedin.com/in/sean-dempsey-154944a9/"
              imageObj={linkedin}
              iconAlt="linkedin"
              underText="Linkedin"
            />
          </CTALinks>
        </CTAContainer>
      </RightSection>
    </MainContainer>
  );
};

export default IntroSlide;
