import React from "react";
import styled from "styled-components";

interface SkillsProps {
  skills: string;
}

const SkillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Tag = styled.span`
  user-select: none;
  width: max-content;
  border: solid;
  border-radius: 2px;
  margin: 2px;
  padding: 2px 8px;
  text-align: center;
`;

const Skills = ({ skills }: SkillsProps) => (
  <SkillsContainer>
    {skills?.split(",").map((skill) => (
      <Tag>{skill}</Tag>
    ))}
  </SkillsContainer>
);

export default Skills;
