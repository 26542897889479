import React, { useEffect, useState } from "react";
import { GlobalStyle } from "../styles";
import styled from "styled-components";

import arrow from "../images/Arrow.svg";

const PageContainer = styled.main`
  position: relative;
  min-height: 100vh;
`;

const EmailCTA = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  right: 5%;
  font-family: DM Sans;
  color: #256d85;
  font-size: 20px;
  writing-mode: vertical-rl;

  @media screen and (max-width: 769px) {
    display: none;
  }
`;

const EmailLink = styled.a`
  font-family: DM Sans;
  color: #256d85;
  padding: 10px;
  transform: translateX(1px);

  &:link {
    text-decoration: none;
  }

  &:visited {
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
  }

  &:active {
    text-decoration: none;
  }

  text-align: center;
`;

const Footer = styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;

  span {
    font-family: Cutive Mono;
    font-size: 20px;
    color: #3bffe4;
  }

  a {
    color: #47b5ff;
  }
`;
const Layout = ({ children }: { children: any }) => {
  return (
    <>
      <GlobalStyle />
      <PageContainer>
        {children}
        <Footer>
          <span>
            Design heavily inspired by{" "}
            <a href="https://brittanychiang.com/">Brittany Chiang</a>
          </span>
        </Footer>
      </PageContainer>
      <EmailCTA>
        <span>
          <EmailLink href="mailto:&#100;&#101;&#109;&#112;&#115;&#046;&#115;&#101;&#097;&#110;&#064;&#103;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;">
            &#100;&#101;&#109;&#112;&#115;&#046;&#115;&#101;&#097;&#110;&#064;&#103;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;
          </EmailLink>
        </span>
        <img src={arrow} alt="arrow"></img>
      </EmailCTA>
    </>
  );
};

export default Layout;
