import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";

interface CTAIconProps {
  url: string;
  imageObj: IGatsbyImageData;
  iconAlt: string;
  underText: string;
  useHTMLlink?: boolean;
}

const HTMLlink = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Cutive Mono;
  color: #47b5ff;
  text-decoration: none;

  &:link {
    text-decoration: none;
  }

  &:visited {
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
  }

  &:active {
    text-decoration: none;
  }

  text-align: center;

  > span {
    width: min-content;
    font-size: small;
  }
`;

export default function CTAIcon({
  url,
  imageObj,
  iconAlt,
  underText,
  useHTMLlink = false,
}: CTAIconProps) {
  const image = getImage(imageObj);
  return useHTMLlink ? (
    <HTMLlink href={url}>
      <GatsbyImage image={image} alt={iconAlt} />
      <span>{underText}</span>
    </HTMLlink>
  ) : (
    <HTMLlink as={Link} to={url}>
      <GatsbyImage image={image} alt={iconAlt} />
      <span>{underText}</span>
    </HTMLlink>
  );
}
