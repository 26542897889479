import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";

interface SkillIconProps {
  imageObj: IGatsbyImageData;
  iconAlt: string;
  text: string;
}

const Skill = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 1.2rem;

  @media screen and (max-width: 769px) {
    justify-content: center;
    flex-direction: column;
  }
`;

const Icon = styled(GatsbyImage)`
  margin-left: 10px;
  margin-right: 10px;
  @media screen and (max-width: 769px) {
    margin-top: 8px;
    margin-bottom: 1px;
  }
`;

const Text = styled.span`
  text-align: center;

  @media screen and (max-width: 769px) {
    display: none;
  }
`;

export default function SkillIcon({ imageObj, iconAlt, text }: SkillIconProps) {
  const image = getImage(imageObj);
  return (
    <Skill>
      <Icon image={image} alt={iconAlt} placeholder="tracedSVG" />
      <Text>{text}</Text>
    </Skill>
  );
}
