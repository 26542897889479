import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

    body {
        font-family: DM Sans;
        color: #47b5ff;
        background-color: #06283D;
        margin-left: 15vw;
        margin-right: 15vw;
        font-size: 25px;

        @media screen and (max-width: 769px) {
            font-size: 16px;
        }
    }

    h1 {
        font-family: DM Sans;
        font-weight: 700;
        font-size: 60px;
        color: #47b5ff;
        margin: 0;
        margin-bottom: 20px;

        @media screen and (max-width: 769px) {
            font-size: 36px;
        }
    }

    h2 {
        font-family: DM Sans;
        font-weight: 400;
        font-size: 40px;
        color: #256d85;
        margin: 0;

        @media screen and (max-width: 769px) {
            font-size: 30px;
        }
    }

    h3 {
        font-family: Cutive Mono;
        font-size: 30px;
        color: #3bffe4;
        margin: 0;
        margin-bottom: 20px;
        @media screen and (max-width: 769px) {
            font-size: 25px;
        }
    }

    p {
        font-family: DM Sans;
        color: #256D85;
    }
`;

export default GlobalStyle;
